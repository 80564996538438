import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Profile from "./pages/Profile";
import Loader from "./components/Loader";
import "./App.css";

function App() {
  const [loadingState, setLoadingState] = useState({
    isLoading: true,
    isVisible: true,
  });

  useEffect(() => {
    // Start the loader immediately
    const loaderTimeout = setTimeout(() => {
      setLoadingState((prevState) => ({ ...prevState, isLoading: false })); // Switch to main content
      const fadeOutTimeout = setTimeout(() => {
        setLoadingState((prevState) => ({ ...prevState, isVisible: false })); // Hide loader after fade-out
      }, 500);

      return () => clearTimeout(fadeOutTimeout); // Cleanup fade-out timer
    }, 2000);

    return () => clearTimeout(loaderTimeout); // Cleanup loader timer
  }, []);

  return (
    <div>
      {loadingState.isVisible && <Loader />} {/* Render loader while visible */}
      {!loadingState.isLoading && (
        
        <Router>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/Profile" element={<Profile />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
