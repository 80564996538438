import React, { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "../components/Profile.css";
import profilePhoto from "../components/Profile-img.png";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Profile() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="App">
      <div className="ProfileCard">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tabs
              className="myTabs"
              value={value}
              onChange={handleChange}
              classes={{
                indicator: "myIndicator",
                flexContainer: "myTabsFlexContainer",
              }}
            >
              <Tab
                className="myTab"
                label="Profile"
                component={Link}
                to="/Profile"
                {...a11yProps(0)}
              />
              <Tab
                className="myTab"
                label="Blog"
                component="a"
                href="https://wmurimi.substack.com/"
                {...a11yProps(1)}
              />
              {/*
              <Tab
                className="myTab"
                label="Resume"
                component={Link}
                to="/Resume"
                {...a11yProps(2)}
              />
              */}
            </Tabs>
          </Grid>
          <div
            className="grid-container"
            style={{ display: "grid", placeItems: "center" }}
          >
            <Grid>
              <img className="Profile-img" src={profilePhoto} alt="profile" />
            </Grid>
            <Grid>
              <div className="highlighted-quote">
                "Do I deserve this?" "Am I worthy of this?"
                <br /> <br />
                So irrelevant. Do you want it?
              </div>
              <div className="Profileabstract-one">
                <br />
                I want to be a professor, staged at the heart of a
                cathedral-like lecture hall. I see the boards stretching from
                end to end, perhaps even one above and below. I feel a
                collection of white chalk in my pocket—among them, a prized
                Hagoromo. <br /> <br />I hear the echo of words on the board as
                my students and I walk from the abstract into the tangible, the
                unknown into the familiar. I imagine myself engaging with every
                student, recognizing the unique light within each one, fueling
                their passion for discovery. I would foster a dialogue, an
                invitation to ask questions, share opinions, and to challenge. I
                want to be a mentor, a role model, a catalyst for curiosity.
              </div>
            </Grid>
            <Grid style={{ alignSelf: "flex-start" }}>
              <p className="ProfileCard-title">
                Wangechi <br /> Murimi
              </p>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/in/wangechimurimi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M 5 3 C 3.895 3 3 3.895 3 5 L 3 19 C 3 20.105 3.895 21 5 21 L 19 21 C 20.105 21 21 20.105 21 19 L 21 5 C 21 3.895 20.105 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 z M 7.7792969 6.3164062 C 6.9222969 6.3164062 6.4082031 6.8315781 6.4082031 7.5175781 C 6.4082031 8.2035781 6.9223594 8.7167969 7.6933594 8.7167969 C 8.5503594 8.7167969 9.0644531 8.2035781 9.0644531 7.5175781 C 9.0644531 6.8315781 8.5502969 6.3164062 7.7792969 6.3164062 z M 6.4765625 10 L 6.4765625 17 L 9 17 L 9 10 L 6.4765625 10 z M 11.082031 10 L 11.082031 17 L 13.605469 17 L 13.605469 13.173828 C 13.605469 12.034828 14.418109 11.871094 14.662109 11.871094 C 14.906109 11.871094 15.558594 12.115828 15.558594 13.173828 L 15.558594 17 L 18 17 L 18 13.173828 C 18 10.976828 17.023734 10 15.802734 10 C 14.581734 10 13.930469 10.406562 13.605469 10.976562 L 13.605469 10 L 11.082031 10 z"></path>
                  </svg>
                </a>

                <a
                  href="https://discuss.systems/@wmurimi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-mastodon"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.19 12.195c2.016-.24 3.77-1.475 3.99-2.603.348-1.778.32-4.339.32-4.339 0-3.47-2.286-4.488-2.286-4.488C12.062.238 10.083.017 8.027 0h-.05C5.92.017 3.942.238 2.79.765c0 0-2.285 1.017-2.285 4.488l-.002.662c-.004.64-.007 1.35.011 2.091.083 3.394.626 6.74 3.78 7.57 1.454.383 2.703.463 3.709.408 1.823-.1 2.847-.647 2.847-.647l-.06-1.317s-1.303.41-2.767.36c-1.45-.05-2.98-.156-3.215-1.928a4 4 0 0 1-.033-.496s1.424.346 3.228.428c1.103.05 2.137-.064 3.188-.189zm1.613-2.47H11.13v-4.08c0-.859-.364-1.295-1.091-1.295-.804 0-1.207.517-1.207 1.541v2.233H7.168V5.89c0-1.024-.403-1.541-1.207-1.541-.727 0-1.091.436-1.091 1.296v4.079H3.197V5.522q0-1.288.66-2.046c.456-.505 1.052-.764 1.793-.764.856 0 1.504.328 1.933.983L8 4.39l.417-.695c.429-.655 1.077-.983 1.934-.983.74 0 1.336.259 1.791.764q.662.757.661 2.046z" />
                  </svg>
                </a>
                <a href="mailto:hello@wangechimurimi.com">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M 4 4 C 2.895 4 2 4.895 2 6 L 2 18 C 2 19.105 2.895 20 4 20 L 20 20 C 21.105 20 22 19.105 22 18 L 22 6 C 22 4.895 21.105 4 20 4 L 4 4 z M 5.5976562 6 L 18.402344 6 L 12 10 L 5.5976562 6 z M 5 8.6269531 L 12 13 L 19 8.6269531 L 19 18 L 5 18 L 5 8.6269531 z"></path>
                  </svg>
                </a>
              </div>
            </Grid>
            <Grid>
              <p className="Profileabstract-two">
                <br />
                In pursuit of this dream, I have achieved several noteworthy
                accomplishments:
                <ul>
                  <li>
                    Cofounder of IwazoLab, a Data Analytics and Research lab
                    located in Nairobi. Our mission is to make Data Science
                    accessible for small and medium-sized enterprises (SMEs) in
                    Kenya. We simplify complex data practices into user-friendly
                    models, empowering small businesses by seamlessly
                    integrating data into their operations.{" "}
                  </li>{" "}
                  <br />
                  <li>
                    Program Coordinator and Host for Kenya-USA Tech (KUSAT)
                    Conferences and webinars, particularly in their Women in
                    Tech forums where I work to cultivate robust networks and
                    foster collaboration, contributing to driving empowerment
                    within this wonderful community.{" "}
                  </li>{" "}
                  <br />
                  <li>
                    Interned at Royal Media Services, a major media house in
                    Eastern Africa, where I trained in telecommunications
                    infrastructure and implementation, protocols, and
                    troubleshooting techniques. I also trained in cloud
                    computing and administration, particularly relevant to the
                    management and security of large volumes of dynamic data as
                    utilised in the industry.{" "}
                  </li>{" "}
                  <br />
                  <li>
                    My academic background is in Applied Computer Science, where
                    I specialized in Distributed Systems & Mobile Computing, and
                    Software Engineering. During my undergraduate, I nurtured a
                    curiosity for knowledge and its dissemination, and pursued a
                    mentorship from Prof. Ngure Wa Mwachofi, Dean & Prof. Ronald
                    Kimani and Prof. Patrick Wamuyu. This intellectual curiosity
                    extended into my role as a programming tutor on campus and
                    volunteering in community outreach.{" "}
                  </li>{" "}
                  <br />
                  <li>
                    Under the mentorship of Dr. Japheth Mursi, my interest in
                    teaching and research flourished we worked in tandem as he
                    supervised my final project on IoT, immersing me in the
                    rigorous standards and practices of research. Furthermore,
                    Dr. Mursi and I extended our efforts by presenting the
                    product to potential investors. This was invaluable exposure
                    to the startup world and honing my skills in effectively
                    communicating my science.{" "}
                  </li>{" "}
                  <br />
                  <li>
                    Accepted as a mentee at the prestigious KamiLimu under the
                    leadership of Dr. Chao Mbogho in 2020. I refined my personal
                    and professional skills, delving into areas such as
                    innovation, writing, and community engagement. A year later,
                    I returned to the organization, taking on the role of a
                    mentor and share the knowledge I had acquired.
                  </li>{" "}
                  <br />
                  <li>
                    Notably, I worked on the Google Web Rangers program,
                    focusing on web safety for individuals under 18, earning
                    recognition in local newspapers for this impactful
                    initiative. I have engaged in various innovation
                    competitions, robotics challenges, and symposiums such as
                    the National Science Congress, securing victories in
                    Technovation and the Mazingira Challenge.
                  </li>{" "}
                  <br />
                </ul>
                Beyond my professional pursuits, I find joy in classical music
                and the piano, a game of badminton, golf, or board games! This
                profile is a glimpse into my diverse experiences, skills, and
                the passion that drives me to continuously contribute to both
                the tech industry and the betterment of society.
              </p>
            </Grid>
            <Grid></Grid>
          </div>
        </Grid>
      </div>

      <div className="mobile-ProfileCard">
        <Grid container spacing={2}>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <Tabs
              className="mobile-myTabs"
              value={value}
              onChange={handleChange}
              centered
              classes={{
                indicator: "myIndicator",
              }}
            >
              <Tab
                className="mobile-myTab"
                label="Profile"
                component={Link}
                to="/Profile"
                {...a11yProps(0)}
              />
              <Tab
                className="mobile-myTab"
                label="Blog"
                component="a"
                href="https://wmurimi.substack.com/"
                {...a11yProps(1)}
              />
              {/*
        <Tab
          className="myTab"
          label="Resume"
          component={Link}
          to="/Resume"
          {...a11yProps(2)}
        />
        */}
            </Tabs>
          </Grid>
        </Grid>

        <Grid container>
          <Grid>
            <img className="Profile-img" src={profilePhoto} alt="profile" />
          </Grid>

          <Grid>
            <p className="ProfileCard-title">
              Wangechi <br /> Murimi
            </p>
            <div class="social-links">
              <a
                href="https://www.linkedin.com/in/wangechimurimi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M 5 3 C 3.895 3 3 3.895 3 5 L 3 19 C 3 20.105 3.895 21 5 21 L 19 21 C 20.105 21 21 20.105 21 19 L 21 5 C 21 3.895 20.105 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 z M 7.7792969 6.3164062 C 6.9222969 6.3164062 6.4082031 6.8315781 6.4082031 7.5175781 C 6.4082031 8.2035781 6.9223594 8.7167969 7.6933594 8.7167969 C 8.5503594 8.7167969 9.0644531 8.2035781 9.0644531 7.5175781 C 9.0644531 6.8315781 8.5502969 6.3164062 7.7792969 6.3164062 z M 6.4765625 10 L 6.4765625 17 L 9 17 L 9 10 L 6.4765625 10 z M 11.082031 10 L 11.082031 17 L 13.605469 17 L 13.605469 13.173828 C 13.605469 12.034828 14.418109 11.871094 14.662109 11.871094 C 14.906109 11.871094 15.558594 12.115828 15.558594 13.173828 L 15.558594 17 L 18 17 L 18 13.173828 C 18 10.976828 17.023734 10 15.802734 10 C 14.581734 10 13.930469 10.406562 13.605469 10.976562 L 13.605469 10 L 11.082031 10 z"></path>
                </svg>
              </a>

              <a
                href="https://discuss.systems/@wmurimi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-mastodon"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.19 12.195c2.016-.24 3.77-1.475 3.99-2.603.348-1.778.32-4.339.32-4.339 0-3.47-2.286-4.488-2.286-4.488C12.062.238 10.083.017 8.027 0h-.05C5.92.017 3.942.238 2.79.765c0 0-2.285 1.017-2.285 4.488l-.002.662c-.004.64-.007 1.35.011 2.091.083 3.394.626 6.74 3.78 7.57 1.454.383 2.703.463 3.709.408 1.823-.1 2.847-.647 2.847-.647l-.06-1.317s-1.303.41-2.767.36c-1.45-.05-2.98-.156-3.215-1.928a4 4 0 0 1-.033-.496s1.424.346 3.228.428c1.103.05 2.137-.064 3.188-.189zm1.613-2.47H11.13v-4.08c0-.859-.364-1.295-1.091-1.295-.804 0-1.207.517-1.207 1.541v2.233H7.168V5.89c0-1.024-.403-1.541-1.207-1.541-.727 0-1.091.436-1.091 1.296v4.079H3.197V5.522q0-1.288.66-2.046c.456-.505 1.052-.764 1.793-.764.856 0 1.504.328 1.933.983L8 4.39l.417-.695c.429-.655 1.077-.983 1.934-.983.74 0 1.336.259 1.791.764q.662.757.661 2.046z" />
                </svg>
              </a>
              <a href="mailto:hello@wangechimurimi.com">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M 4 4 C 2.895 4 2 4.895 2 6 L 2 18 C 2 19.105 2.895 20 4 20 L 20 20 C 21.105 20 22 19.105 22 18 L 22 6 C 22 4.895 21.105 4 20 4 L 4 4 z M 5.5976562 6 L 18.402344 6 L 12 10 L 5.5976562 6 z M 5 8.6269531 L 12 13 L 19 8.6269531 L 19 18 L 5 18 L 5 8.6269531 z"></path>
                </svg>
              </a>
            </div>
          </Grid>
        </Grid>

        <Grid>
          <div className="highlighted-quote">
            "Do I deserve this?" "Am I worthy of this?"
            <br /> <br />
            So irrelevant. Do you want it?
          </div>
          <p className="Profileabstract-one">
            In pursuit of this dream, I have achieved several noteworthy
            accomplishments:
            <ul>
              <li>
                Cofounder of IwazoLab, a Data Analytics and Research lab located
                in Nairobi. Our mission is to make Data Science accessible for
                small and medium-sized enterprises (SMEs) in Kenya. We simplify
                complex data practices into user-friendly models, empowering
                small businesses by seamlessly integrating data into their
                operations.{" "}
              </li>{" "}
              <br />
              <li>
                Program Coordinator and Host for Kenya-USA Tech (KUSAT)
                Conferences and webinars, particularly in their Women in Tech
                forums where I work to cultivate robust networks and foster
                collaboration, contributing to driving empowerment within this
                wonderful community.{" "}
              </li>{" "}
              <br />
              <li>
                Interned at Royal Media Services, a major media house in Eastern
                Africa, where I trained in telecommunications infrastructure and
                implementation, protocols, and troubleshooting techniques. I
                also trained in cloud computing and administration, particularly
                relevant to the management and security of large volumes of
                dynamic data as utilised in the industry.{" "}
              </li>{" "}
              <br />
              <li>
                My academic background is in Applied Computer Science, where I
                specialized in Distributed Systems & Mobile Computing, and
                Software Engineering. During my undergraduate, I nurtured a
                curiosity for knowledge and its dissemination, and pursued a
                mentorship from Prof. Ngure Wa Mwachofi, Dean & Prof. Ronald
                Kimani and Prof. Patrick Wamuyu. This intellectual curiosity
                extended into my role as a programming tutor on campus and
                volunteering in community outreach.{" "}
              </li>{" "}
              <br />
              <li>
                Under the mentorship of Dr. Japheth Mursi, my interest in
                teaching and research flourished we worked in tandem as he
                supervised my final project on IoT, immersing me in the rigorous
                standards and practices of research. Furthermore, Dr. Mursi and
                I extended our efforts by presenting the product to potential
                investors. This was invaluable exposure to the startup world and
                honing my skills in effectively communicating my science.{" "}
              </li>{" "}
              <br />
              <li>
                Accepted as a mentee at the prestigious KamiLimu under the
                leadership of Dr. Chao Mbogho in 2020. I refined my personal and
                professional skills, delving into areas such as innovation,
                writing, and community engagement. A year later, I returned to
                the organization, taking on the role of a mentor and share the
                knowledge I had acquired.
              </li>{" "}
              <br />
              <li>
                Notably, I worked on the Google Web Rangers program, focusing on
                web safety for individuals under 18, earning recognition in
                local newspapers for this impactful initiative. I have engaged
                in various innovation competitions, robotics challenges, and
                symposiums such as the National Science Congress, securing
                victories in Technovation and the Mazingira Challenge.
              </li>{" "}
              <br />
            </ul>
            Beyond my professional pursuits, I find joy in classical music and
            the piano, a game of badminton, golf, or board games! This profile
            is a glimpse into my diverse experiences, skills, and the passion
            that drives me to continuously contribute to both the tech industry
            and the betterment of society.
            <br /> <br />
          </p>
        </Grid>
      </div>

      {/*TODO: Add Copyright Sticky Footer  */}
    </div>
  );
}

export default Profile;
